@charset "utf-8";

@font-face {
    font-family: 'Canto';
    src: url('../fonts/Canto-Roman.eot');
    src: url('../fonts/Canto-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Canto-Roman.woff2') format('woff2'),
        url('../fonts/Canto-Roman.woff') format('woff'),
        url('../fonts/Canto-Roman.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Canto Pen';
    src: url('../fonts/CantoPen-Roman.eot');
    src: url('../fonts/CantoPen-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CantoPen-Roman.woff2') format('woff2'),
        url('../fonts/CantoPen-Roman.woff') format('woff'),
        url('../fonts/CantoPen-Roman.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Canto Pen';
    src: url('../fonts/CantoPen-Bold.eot');
    src: url('../fonts/CantoPen-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CantoPen-Bold.woff2') format('woff2'),
        url('../fonts/CantoPen-Bold.woff') format('woff'),
        url('../fonts/CantoPen-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Import partials
@import 
"theme",
"styles";