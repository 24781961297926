$breakpoint-sm-max: 720px;
$breakpoint-sm-min: calc(#{$breakpoint-sm-max} - 1px);
$breakpoint-md-max: 1024px;
$breakpoint-md-min: calc(#{$breakpoint-md-max} - 1px);

// Typography
$cantoPen:          'Canto Pen', serif;
$cantoRegular:      'Canto', serif;

:root {
    --sans-serif: 'Inter', sans-serif;

    --color-white: #FFFFFF;
    --color-cream: #FEFCF7;
    --color-black: #000000;
    --color-grey:  #696969;
    --color-text:  #151616;
    --color-accent:#2F6864;
    --color-accent-rgb:47,104,100;
}