/** Reset basic elements **/
html, body, div, main, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del,
dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav, output,
ruby, section, summary, time, mark, audio, video {
   margin: 0;
   padding: 0;
   border: 0;
   outline: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
   background: 0 0;
   text-rendering: optimizeLegibility;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

::-moz-selection {
   background: var(--color-accent);
   color: var(--color-white);
}

::selection {
   background: var(--color-accent);
   color: var(--color-white);
}

html {
   scroll-behavior: smooth;
}

body {
   color: var(--color-text);
   font-family: var(--sans-serif);
   // background-color: var(--color-cream);
   background-color: var(--color-white);
}

a {
   color: var(--color-text);
}

a:hover {
   color: var(--color-black);
}

p {
   margin-bottom: 0.75em;
}

li {
   padding-left: 12px;
}

ul {
   margin: 12px 0 12px 32px;
}

i {
   font-style: italic;
}

.pen {
   font-family: $cantoPen;
}

.serif {
   font-family: $cantoRegular;
}

blockquote {
   font-family: $cantoPen;
   margin: 2em 0;
}

@media screen and (min-width: 30em) {
   .mw7-5-ns {
      max-width: 52rem;
   }
}

.aside {
   margin-top: 1.5em;
   font-size: .95em;
}

.work-link {
   text-decoration: none;
   line-height: 2em;
   padding-bottom: 0.05em;
   border-bottom-width: 1px;
   border-bottom-style: solid;
   border-bottom-color: rgba(var(--color-accent-rgb), .3);
   transition: 500ms;
}

.work-link:hover {
   border-bottom-color: rgba(var(--color-accent-rgb), 1);
}

.footer-adjustment {
   min-height: calc(100vh - 209.5px);
}

#casestudy {
   h2, p, ol, li, blockquote {
      font-size: 1.25em;
      line-height: 1.3em;
   }

   // @media screen and (min-width: 30em) {
   //    h2, p, ol, li, blockquote {
   //       font-size: 1.25em;
   //    }
   // }
}

#works-list {
   margin-left: 36px;
}

#works-list li {
   list-style-type: decimal-leading-zero;
   margin-bottom: 1.25em;
}

.cool {
   letter-spacing: 0.1em;
   font-style: italic;
}